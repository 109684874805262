<header>
    <a class="logo">
        <img src="assets/img/logo.jpg" alt="Junkie Tattoos Logo" class="image" routerLink="/home">
    </a>
   <div class="language">
    <!-- <button (click)="useLanguage('de')">DE</button>
        <button (click)="useLanguage('en')">EN</button> --> 
        <a href="https://www.instagram.com/junkie.tattoos/?hl=de" class="instagram"> <img class="insta"
                src="assets/img/instagram.png"></a>
                <a href="https://junkietattoos.bigcartel.com" class="shop"> <img class="shop"
                    src="assets/img/shoppingcart.png"></a>
                    <a class="bold-links" routerLink="/care" routerLinkActive="active"> <img class="care" src="assets/img/care2.png"> </a>
            </div> 
</header>