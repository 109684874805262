<div class="home">
    <div class="left">
        <h1>
            {{'HOME.HELLO' | translate }}
        </h1>
        <p>
            {{'HOME.ALIAS' | translate }} <br>

            {{'HOME.DO' | translate }} <br>
            {{'HOME.LINK' | translate }}
            <a href="https://www.instagram.com/junkie.tattoos/?hl=de" class="link"><br>

                {{'HOME.INSTA' | translate }}
            </a>
            <br>
            <br>
            Für Merch, Leinwände oder ähnliches, schau doch mal beim Shop vorbei:<br> <a
                href="https://junkietattoos.bigcartel.com" class="link">Junkie Tattoos Shop</a><br> <br>
            {{'HOME.APPOINTMENT' | translate }}

        </p>

        <button type="button" class="btn btn-dark" routerLink="/contact" routerLinkActive="active"> {{'HOME.BUTTON' |
            translate}} </button>

    </div>
    <!-- Maybe use curator.io as instafeed-->
</div>